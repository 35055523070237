<template>
  <!-- Onboarding form -->
  <div class="account__form">
    <form class="form" @submit.prevent="login">
      <p class="account__head__title mb-5">Organization Admin Details</p>
      <div class="row">
        <div class="col-6">
          <BaseInput
            label="Admin fullname"
            placeholder="Enter admin fullname"
            v-model="form.fullName"
            type="text"
            class="mb-0"
          >
          </BaseInput>
          <div class="error" v-if="!$v.form.fullName.required">
            <span class="text-danger">This is required</span>
          </div>
        </div>

        <div class="col-6">
          <BaseInput
            label="Admin username"
            placeholder="Enter admin username"
            v-model="form.username"
            type="text"
            class="mb-0"
          >
          </BaseInput>
          <div class="error" v-if="!$v.form.username.required">
            <span class="text-danger">This is required</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <BaseInput
            label="Admin Email"
            placeholder="Enter email"
            v-model="form.email"
            type="email"
            class="mt-5 mb-0"
          >
          </BaseInput>
          <div class="error" v-if="!$v.form.email.required">
            <span class="text-danger">This is required</span>
          </div>
          <div class="error" v-if="!$v.form.email.email">
            <span class="text-danger">Enter a valid email</span>
          </div>
        </div>

        <div class="col-6">
          <BaseInput
            label="Admin Phone number"
            placeholder="Enter phone number"
            v-model="form.PhoneNumber"
            type="text"
            class="mt-5 mb-5"
          >
          </BaseInput>
        </div>
      </div>

      <div
        class="form__item form__action d-flex align-items-center justify-content-between"
      >
        <div style="position: relative; z-index: 3" class="row">
          <button
            type="submit"
            role="button"
            href="#"
            class="button form__button form__button--lg"
          >
            {{ process ? "please wait..." : "Continue" }}
          </button>

          <a
            role="button"
            class="modal__close form__action__text form__action__text text--capital ml6"
            style="display: flex; align-items: center"
            @click="handlePrevSection"
          >
            Go back
          </a>

          <img
            style="position: absolute; left: -5rem; top: -2.5rem; z-index: -1"
            src="/assets/img/dotted-bg.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/_base-Input.vue";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "orgAdminDetails",
  data() {
    return {
      form: {
        fullName: "",
        email: "",
        PhoneNumber: "",
        username: "",
      },
      process: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: `Welcome back`,
      },
    };
  },

  methods: {
    login() {
      this.process = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.process = false;
        return;
      } else {
        setTimeout(() => {
          this.process = false;
          this.$emit("go-to-next-section", this.form);
        }, 300);
      }
    },

    handlePrevSection() {
      this.$emit("go-to-previous-section");
    },
  },

  validations: {
    form: {
      fullName: {
        required,
      },
      username: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },

  components: {
    BaseInput,
  },
};
</script>

export default {
  data() {
    return {
      activeComponentIndex: 0,
      navigationHistory: [0],
    };
  },

  computed: {
    // Navigation property
    navigationHistoryItemsCounts() {
      return this.navigationHistory.length;
    },

    indexOfLastNavigationItem() {
      return this.navigationHistoryItemsCounts === 0
        ? 0
        : this.navigationHistoryItemsCounts - 1;
    },
  },

  methods: {
    handleNextSection() {
      this.activeComponentIndex += 1;
    },

    handlePreviousSection() {
      if (this.activeComponentIndex === 0) return;
      this.activeComponentIndex -= 1;
    },

    goBack() {
      if (this.navigationHistoryItemsCounts > 1) {
        this.navigationHistory.splice(this.indexOfLastNavigationItem, 1);

        // get the value of the last item in the array and set it as the active component index
        this.$nextTick(() => {
          this.activeComponentIndex =
            this.navigationHistory[this.indexOfLastNavigationItem];
        });
      }
    },

    setActiveComponentIndex(componentIndex) {
      this.activeComponentIndex = componentIndex;
      // update the array that keeps track of the navigation history
      this.navigationHistory.push(componentIndex);
    },
  },
};

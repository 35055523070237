<template>
  <div class="form__item">
    <slot name="label">
      <label v-if="label" class="form__label">
        {{ label }}
      </label>
    </slot>
    <slot name="textarea">
      <textarea
        :value="value"
        v-bind="$attrs"
        v-on="listeners"
        @input="updateValue"
        class="input form__input"
      ></textarea>
    </slot>
  </div>
</template>

<script>
export default {
  name: "BaseTextArea",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      description: "Input label",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
  },

  model: {
    prop: "value",
    event: "input",
  },

  data() {
    return {
      focused: false,
    };
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },

  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    updateValue(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>
